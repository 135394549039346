import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link, StaticQuery } from 'gatsby';
import { Icon, Menu } from 'antd';

const Nav = ({ hivernage, racingTeam }) => (
	<StaticQuery
		query={graphql`
	      query {
	        site {
	          siteMetadata {
	            mainNavigation {
	              name
	              path
	              icon
	            }
	          }
	        }
	      }
	    `}
		render={data => {
			const navigation = [ ...data.site.siteMetadata.mainNavigation ];

			if (hivernage) {
				navigation.splice(navigation.length - 1, 0, {
					name: `Hivernage`,
					path: `/hivernage`,
					icon: false
				});
			}

			if (racingTeam) {
				navigation.splice(navigation.length - 1, 0, {
					name: `Racing Team`,
					path: `/racing-team`,
					icon: false
				});
			}

			return (
				<nav>
					<Menu mode="horizontal">
						{navigation.map((item, key) => (
							<Menu.Item key={key}>
								<Link to={item.path} activeClassName="active">
									{item.icon && <Icon type={item.icon} />}
									{item.name}
								</Link>
							</Menu.Item>
						))}
						<Menu.Item key="facebook">
							<a href="https://www.facebook.com/BlackWayMotorcycles/" target="_blank" rel="noreferrer noopener"><Icon type="facebook" style={{ fontSize: 20 }} /></a>
						</Menu.Item>
						<Menu.Item key="instagram">
							<a href="https://www.instagram.com/blackwaymotorcycles/" target="_blank" rel="noreferrer noopener"><Icon type="instagram" style={{ fontSize: 20 }} /></a>
						</Menu.Item>
					</Menu>
				</nav>
			)
		}}
	/>
);

export default Nav;
