import React, { useState, useEffect } from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';
import { Icon } from 'antd';
import Prismic from 'prismic-javascript';
import PrismicDOM from "prismic-dom";

const prismicURL = 'https://blackway.prismic.io/api/v2';

const Footer = () => {
	const [ schedule, setSchedule ] = useState(null);

	useEffect(() => {
		Prismic.getApi(prismicURL).then((api) => api.query(
			Prismic.Predicates.at('document.type', 'horaires')
		))
			.then(response => {
				setSchedule(PrismicDOM.RichText.asHtml(response.results[0].data.horaires));
			});
	}, []);

	return (
		<StaticQuery
			query={graphql`
		      query SiteFooterQuery {
		        site {
		          siteMetadata {
		            title
		            address
		            postal
		            city
		            province
		            country
		            phone
		            fax
		            mobilePhone
		            email
		            footerNavigation {
		              name
		              path
		            }
		          }
		        }
		      }
		    `}
			render={data => (
				<footer>
					<div>
						<h4>Horaires:</h4>
						<div className="schedules" dangerouslySetInnerHTML={{ __html: schedule }} />
					</div>
					<div>
						<div className="contact-mobile contact-tablet" style={{ marginBottom: 20 }}>
							<h4>Suivez-nous:</h4>
							<a href="https://www.facebook.com/BlackWayMotorcycles/" target="_blank" rel="noreferer noopener"><Icon type="facebook" style={{ fontSize: 40, marginRight: 20 }} /></a>
							<a href="https://www.instagram.com/blackwaymotorcycles/" target="_blank" rel="noreferer noopener"><Icon type="instagram" style={{ fontSize: 40 }} /></a>
						</div>
						<div className="contact-tablet">
							<h4>Adresse:</h4>
							<p>{data.site.siteMetadata.address}<br />{data.site.siteMetadata.postal} {data.site.siteMetadata.city}<br />{data.site.siteMetadata.province}, {data.site.siteMetadata.country}</p>
						</div>
						<div className="contact-tablet">
							{data.site.siteMetadata.footerNavigation.map((item, key) => (
								<React.Fragment key={key}>
									<Link key={key} to={item.path}>
										{item.icon && <Icon type={item.icon} />}
										{item.name}
									</Link>
									{key < data.site.siteMetadata.footerNavigation.length - 1 && ` - `}
								</React.Fragment>
							))}
						</div>
						<div className="contact-tablet" style={{ marginTop: 10 }}>
							© {new Date().getFullYear()} {data.site.siteMetadata.title}
						</div>
					</div>
					<div>
						<h4 className="contact-desktop">Suivez-nous:</h4>
						<div className="contact-desktop" style={{ marginBottom: 40 }}>
							<a href="https://www.facebook.com/BlackWayMotorcycles/" target="_blank" rel="noreferrer noopener"><Icon type="facebook" style={{ fontSize: 48, marginRight: 20 }} /></a>
							<a href="https://www.instagram.com/blackwaymotorcycles/" target="_blank" rel="noreferrer noopener"><Icon type="instagram" style={{ fontSize: 48 }} /></a>
						</div>
						<small>Site internet par <a href="https://www.nammu.ch/" target="_blank" rel="noreferrer noopener">nammu.ch</a></small>
					</div>
				</footer>
			)}
		/>
	);
}

export default Footer;
