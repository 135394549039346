import React from "react"
import PropTypes from "prop-types"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import { globalHistory } from "@reach/router"
const path = globalHistory.location.pathname

const Header = () => (

  <StaticQuery
    query={graphql`
	      query {
	        site {
	          siteMetadata {
	            title
	            subtitle
	          }
	        }
	        logo: file(relativePath: { eq: "logo.png" }) {
	          childImageSharp {
	            fluid(maxWidth: 800) {
		          ...GatsbyImageSharpFluid
		        }
	          }
	        }
	        logoBWRT: file(relativePath: { eq: "BWRT_logo.png" }) {
	          childImageSharp {
	            fluid(maxWidth: 800) {
		          ...GatsbyImageSharpFluid
		        }
	          }
	        }
	      }
	    `}
    render={data => (

      <header>
        <Link to="/">
          {console.log(data.site.siteMetadata.subtitle)}
					{data.site.siteMetadata.subtitle === "Black Way Racing Team" ?
						<Img
              fluid={data.logoBWRT.childImageSharp.fluid}
							alt="BlackWay Motorcycles - Spécialiste Harley-Davidson & Buell à Bernex, Genève"
							style={{ maxWidth: 800 }}
						/>
            :
            <Img
              fluid={data.logo.childImageSharp.fluid}
              alt="BlackWay Motorcycles - Spécialiste Harley-Davidson & Buell à Bernex, Genève"
              style={{ maxWidth: 800 }}
            />}
        </Link>
				{data.site.siteMetadata.subtitle === "Black Way Racing Team" ? <div></div>
          :
          <h1 style={{ margin: 0 }}>{data.site.siteMetadata.subtitle}</h1>}
      </header>
    )}
  />
)

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ``
}

export default Header
