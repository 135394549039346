import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Prismic from 'prismic-javascript';

import Nav from './nav';
import Header from './header';
import Footer from './footer';
import MobileNav from './mobile-nav';

import './layout.less';
import 'antd/dist/antd.less';

const prismicURL = 'https://blackway.prismic.io/api/v2';

const Layout = ({ images, url, children }) => {
	const [ ieWarning, setIEWarning ] = useState(false);
	const [ hivernage, setHivernage ] = useState(false);
	const [ racingTeam, setRacingTeam ] = useState(false);

	useEffect(() => {
		if (window.MSCompatibleInfo != null) {
			setIEWarning(true);
		}

		(async () => {

			Prismic.getApi(prismicURL).then((api) => api.query(
				Prismic.Predicates.at('document.type', 'hivernage')
			))
				.then(response => {
					setHivernage(!!response.results[0].data.enabled);
				});

			Prismic.getApi(prismicURL).then((api) => api.query(
				Prismic.Predicates.at('document.type', 'black_way_racing_team')
			))
				.then(response => {
					setRacingTeam(!!response.results[0].data.enabled);
				});

		})();
	}, []);

	return (
		<>
			{ieWarning && (
				<div style={{ position: 'fixed', top: 0, left: 0, width: '100vw', height: '100vh', background: 'rgba(0, 0, 0, 0.5)', zIndex: 1000 }}>
					<div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
						<div style={{ width: 500, maxWidth: '80vw', background: '#EEE', padding: '20px 30px' }}>
							<h3>Votre navigateur n'est pas à jour</h3>
							<p>Vous utilisez un navigateur trop vieux et le site ne peut pas être correctement affiché.</p>
							<p>Rendez-vous sur <a href="https://browser-update.org/fr/update.html" target="_blank" rel="nofollow">https://browser-update.org/fr/update.html</a> pour mettre à jour votre navigateur.</p>
						</div>
					</div>
				</div>
			)}
			<Nav hivernage={hivernage} racingTeam={racingTeam}  />
			<Header />
			<main>{children}</main>
			<Footer />
			<MobileNav hivernage={hivernage} racingTea={racingTeam} />
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Layout;
