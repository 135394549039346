import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'

function SEO({ title, description, url, lang, image }) {
	const { site } = useStaticQuery(
		graphql`
          query {
            site {
              siteMetadata {
                title
                author
                baseURL
              }
            }
          }
        `
	);

	title = `${title} - Spécialiste Harley-Davidson & Buell`;

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={`%s | ${site.siteMetadata.title}`}
			meta={[
				{
					name: `description`,
					content: description,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: description,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:url`,
					content: `${site.siteMetadata.baseURL}${url}`
				},
				{
					property: `og:site_name`,
					content: site.siteMetadata.title
				},
				{
					name: `twitter:card`,
					content: `summary`,
				},
				{
					name: `twitter:creator`,
					content: site.siteMetadata.author,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: description,
				},
			]}
		>
			<link rel="canonical" href={`${site.siteMetadata.baseURL}${url}`} />
		</Helmet>
	)
}

SEO.defaultProps = {
	lang: `fr`,
	meta: [],
	description: ``,
	image: ``,
	url: `/`
};

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
};

export default SEO
